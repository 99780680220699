import React from 'react'
import { useLocation } from 'react-router-dom'
import MaximumSpeedCalculator from './Calculators/MaximumSpeedCalculator'
import CarbonFootprintCalculator from './Calculators/CarbonFootprintCalculator'
import TransactionCapacityCalculator from './Calculators/TransactionCapacityCalculator'
import Layout from '../../components/Layout'
import TruckScaleCalculatorList from './TruckScaleCalculatorList'
import calculators from './calculators'

const TruckScaleCalculator = () => {
    return (
        <Layout>
            <div className="section-wrapper page-content">
                <div id="truck-scale-calculator">
                    <Calculator />
                </div>
                <h1>Select a SURVIVOR® OTR-IMS Calculator</h1>
                <TruckScaleCalculatorList />
            </div>
        </Layout>
    )
}

const Calculator = () => {
    const location = useLocation()

    if (location.pathname.includes('maximum-speed')) {
        const calculator = calculators.find(x => x.slug === 'maximum-speed')

        return (
            <>
                <h1>{calculator.title}</h1>
                <p>{calculator.formDescription}</p>
                <MaximumSpeedCalculator />
            </>
        )
    }

    if (location.pathname.includes('carbon-footprint')) {
        const calculator = calculators.find(x => x.slug === 'carbon-footprint')

        return (
            <>
                <h1>{calculator.title}</h1>
                <p>{calculator.formDescription}</p>
                <CarbonFootprintCalculator />
            </>
        )
    }

    if (location.pathname.includes('transaction-capacity')) {
        const calculator = calculators.find(x => x.slug === 'transaction-capacity')

        return (
            <>
                <h1>{calculator.title}</h1>
                <p>{calculator.formDescription}</p>
                <TransactionCapacityCalculator />
            </>
        )
    }

    return null
}

export default TruckScaleCalculator
