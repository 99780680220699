import React from 'react'
import TruckScaleCalculatorCard from './TruckScaleCalculatorCard'
import calculators from './calculators'

const TruckScaleCalculatorList = () => {
    return (
        <div id="truck-scale-calculator-list" className="col-section col-no-margin">
            {calculators.map(calculator => {
                return (
                    <div className="col-xs-12 col-sm-6 col-md-4" key={calculator.slug}>
                        <TruckScaleCalculatorCard {...calculator} />
                    </div>
                )
            })}
        </div>
    )
}

export default TruckScaleCalculatorList
