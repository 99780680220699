import React from 'react'
import PropTypes from 'prop-types'

const ReportTable = ({ children }) => {
    return (
        <table>
            <thead>
                <tr>
                    <th>INPUT</th>
                    <th>RESULTS</th>
                </tr>
            </thead>
            <tbody>{children}</tbody>
        </table>
    )
}

ReportTable.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.object
    ]).isRequired
}

export default ReportTable
