import React from 'react'
import { useLocation } from 'react-router-dom'
import Layout from '../../components/Layout'
import MaximumSpeedReport from './Reports/MaximumSpeedReport'
import CarbonFootprintReport from './Reports/CarbonFootprintReport'
import TransactionCapacityReport from './Reports/TransactionCapacityReport'

const TruckScaleCalculatorReport = () => {
    return (
        <Layout>
            <div className="section-wrapper page-content">
                <div id="truck-scale-report">
                    <Report />
                </div>
            </div>
        </Layout>
    )
}

const Report = () => {
    const { pathname } = useLocation()

    if (pathname.includes('maximum-speed')) {
        return <MaximumSpeedReport />
    }

    if (pathname.includes('carbon-footprint')) {
        return <CarbonFootprintReport />
    }

    if (pathname.includes('transaction-capacity')) {
        return <TransactionCapacityReport />
    }

    return <>Hi</>
}

export default TruckScaleCalculatorReport
