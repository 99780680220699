import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Formik } from 'formik'

import Layout from '../../../components/Layout'
import LivestockProductList from '../LivestockProductList'
import LivestockProductCard from '../LivestockProductCard'
import { ScaleToolsNumberInput } from '../../../components/Forms/ScaleToolsTextInput'
import ScaleToolsSelectComponent from '../../../components/Forms/ScaleToolsSelect'
import { LivestockValidation } from '../../../components/Forms/Validation'
import { setLivestockCalculatorData } from '../../../store/actions/livestockActions'
import LivestockCalculate from '../LivestockCalculator'

const LivestockShrinkCalculate = ({ currentLivestock, setLivestockCalcData, currentForm }) => {
    if (!currentLivestock) {
        return (
            <Layout>
                <div id="page-main" className="section-wrapper page-content">
                    <h1>Not Found</h1>
                    <Link to="/livestock-shrink">Back</Link>
                </div>
            </Layout>
        )
    }

    const [numberOfPayments, setNumberOfPayments] = useState('')

    const { id, title, dimensions } = currentLivestock

    const HandleSubmit = values => {
        const { dimension } = values
        const selectedDimension = dimensions.find(i => i.id === dimension) || dimensions[0]
        const { totalPrice } = selectedDimension
        const {
            perShipment: { shipmentsToPayOff }
        } = LivestockCalculate({ ...values, totalPrice })
        setNumberOfPayments(
            shipmentsToPayOff.toLocaleString(undefined, {
                maximumFractionDigits: 2
            })
        )
    }

    const dimensionOptions = dimensions.map(dimension => {
        const { id: key, description: value } = dimension
        return {
            key,
            value
        }
    })

    const InitialValues = (currentForm && currentForm[id]) || {
        dimension: dimensionOptions.length > 0 ? dimensionOptions[0].key : -1,
        headCount: 0,
        weightPerHead: 0,
        pricePerPound: 0,
        shrinkAtPos: 0,
        shrinkToMove: 0
    }

    return (
        <Layout>
            <div id="page-main" className="section-wrapper page-content">
                <h1>{title}</h1>

                <div id="livestock-calc" className="col-section col-no-margin">
                    <div className="product-card col-xs-12 col-sm-6 col-md-4">
                        <LivestockProductCard {...currentLivestock} hideButton />
                    </div>
                    <Formik
                        onSubmit={HandleSubmit}
                        initialValues={InitialValues}
                        validationSchema={LivestockValidation}
                    >
                        {({ handleSubmit, values, resetForm, isValid }) => {
                            const ResetForm = () => {
                                setNumberOfPayments(null)
                                resetForm()
                            }

                            // every time the form changes this will run and set the values
                            setLivestockCalcData(id, values)

                            return (
                                <>
                                    <div className="col-xs-12 col-sm-6 col-md-4">
                                        <form id="livestock-shrink">
                                            <ScaleToolsSelectComponent
                                                name="dimension"
                                                label="Select Product Dimensions:"
                                                options={dimensionOptions}
                                            />
                                            <ScaleToolsNumberInput
                                                label="Number of Head:"
                                                name="headCount"
                                            />
                                            <ScaleToolsNumberInput
                                                label="Average Weight Per Head:"
                                                name="weightPerHead"
                                            />
                                            <ScaleToolsNumberInput
                                                label="Selling Price per Pound
                                            (US$):"
                                                name="pricePerPound"
                                            />
                                            <ScaleToolsNumberInput
                                                label="Pencil Shrink at Point of Sale (%):"
                                                name="shrinkAtPos"
                                            />
                                            <ScaleToolsNumberInput
                                                label="Estimated Shrink to Move
                                            to Scale Off Site (%):"
                                                name="shrinkToMove"
                                            />
                                        </form>
                                    </div>
                                    <div
                                        id="livestock-result"
                                        className="col-xs-12 col-sm-6 col-md-4"
                                    >
                                        <h3>Result</h3>
                                        <div>
                                            <p>Shipments to Pay off the scale</p>
                                            <h2>{numberOfPayments || 'Calculate'}</h2>
                                            <button type="submit" onClick={handleSubmit}>
                                                Calculate
                                            </button>
                                            <button
                                                type="button"
                                                onClick={ResetForm}
                                                className="reset-btn"
                                            >
                                                Reset
                                            </button>
                                        </div>
                                        {isValid && numberOfPayments && (
                                            <Link
                                                to={`/livestock-shrink/${id}/details`}
                                                className="btn btn-grey"
                                            >
                                                See Calculations
                                            </Link>
                                        )}
                                    </div>
                                </>
                            )
                        }}
                    </Formik>
                </div>

                <h1>Select a Different Product</h1>
                <LivestockProductList currentProduct={id} />
            </div>
        </Layout>
    )
}

function mapStateToProps(state, ownProps) {
    const {
        livestock: { livestock, current }
    } = state

    const {
        match: {
            params: { id }
        }
    } = ownProps

    let currentLivestock = null
    if (livestock && id) {
        currentLivestock = livestock.find(o => o.id === Number(id))
    }

    return {
        currentLivestock,
        currentForm: current
    }
}

LivestockShrinkCalculate.propTypes = {
    currentForm: PropTypes.shape({}).isRequired,
    currentLivestock: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        description: PropTypes.string,
        imageUrl: PropTypes.string,
        title: PropTypes.string,
        dimensions: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                description: PropTypes.string,
                totalPrice: PropTypes.number,
                perShipment: PropTypes.shape({
                    shipmentsToPayOff: PropTypes.number
                })
            })
        )
    }),
    setLivestockCalcData: PropTypes.func.isRequired
}

LivestockShrinkCalculate.defaultProps = {
    currentLivestock: null
}

function mapDispatchToProps(dispatch) {
    return {
        setLivestockCalcData: (id, values) => dispatch(setLivestockCalculatorData(id, values))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LivestockShrinkCalculate)
