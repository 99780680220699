import React, { useState } from 'react'
import { Formik } from 'formik'
import { Link, useLocation } from 'react-router-dom'
import { TruckScaleMaximumSpeedValidation } from '../../../components/Forms/Validation'
import { ScaleToolsNumberInput } from '../../../components/Forms/ScaleToolsTextInput'
import MaximumSpeedLogo from '../../../assets/images/truck-scale-maximum-speed.png'
import calculators from '../calculators'

const minimumTimeOnScaleInSeconds = 1.5
const superDuperSecretValue = 1.4667
const legalMaximumSpeedInMph = 6

const calculator = calculators.find(x => x.slug === 'maximum-speed')

const MaximumSpeedCalculator = () => {
    const {
        state = {
            maxSpeed: 0,
            minimumTimeOnScaleInSeconds: 1.5,
            scaleLength: 0,
            wheelbase: 0
        }
    } = useLocation()

    const [maxSpeedInMph, setMaxSpeedInMph] = useState(state.maxSpeed)

    const isLegalForTrade = maxSpeedInMph > 0 && maxSpeedInMph < legalMaximumSpeedInMph
    const isNotLegalForTrade = maxSpeedInMph > 0 && maxSpeedInMph >= legalMaximumSpeedInMph
    const displayMaxSpeed = Math.min(legalMaximumSpeedInMph, maxSpeedInMph).toFixed(1)

    const onCalculate = values => {
        setMaxSpeedInMph(
            (values.scaleLength - values.wheelbase) /
                superDuperSecretValue /
                minimumTimeOnScaleInSeconds
        )
    }

    const onReset = () => {
        setMaxSpeedInMph(0)
    }

    return (
        <div id="maximum-speed-calculator">
            <div id="header">
                <img src={MaximumSpeedLogo} alt="Maxiumum Speed Logo" />
                <p style={{ marginLeft: '10px' }}>{calculator.formDescription}</p>
            </div>

            <Formik
                initialValues={{ ...state }}
                onSubmit={onCalculate}
                validationSchema={TruckScaleMaximumSpeedValidation}
            >
                {({ handleSubmit, isValid, resetForm, values }) => {
                    return (
                        <>
                            <form onSubmit={handleSubmit}>
                                <div className="form-field">
                                    <ScaleToolsNumberInput
                                        label="Length of Vehicle Wheelbase in Feet)"
                                        name="wheelbase"
                                        onFocus={e => e.target.select()}
                                        showErrorText
                                    />
                                </div>

                                <div className="form-field">
                                    <ScaleToolsNumberInput
                                        label="Length of Scale in Feet"
                                        name="scaleLength"
                                        onFocus={e => e.target.select()}
                                        showErrorText
                                    />
                                </div>

                                <div id="min-time">Minimum time on the scale is 1.5 seconds</div>
                            </form>

                            <div id="maximum-speed-result">
                                <h3>Result</h3>

                                <div>
                                    <p>Maximum Speed</p>
                                    <h2>
                                        <span
                                            className={`${
                                                isLegalForTrade ? 'legal-for-trade-speed' : ''
                                            } ${
                                                isNotLegalForTrade
                                                    ? 'not-legal-for-trade-speed'
                                                    : ''
                                            }`}
                                        >
                                            {displayMaxSpeed} mph
                                        </span>
                                    </h2>

                                    <h3>
                                        {isLegalForTrade && (
                                            <span className="legal-for-trade-speed">
                                                Legal for Trade Speed
                                            </span>
                                        )}

                                        {isNotLegalForTrade && (
                                            <span className="not-legal-for-trade-speed">
                                                Not Legal for Trade Speed
                                            </span>
                                        )}

                                        {maxSpeedInMph === null && (
                                            <span style={{ color: 'white' }}>0</span>
                                        )}
                                    </h3>

                                    <button
                                        disabled={!isValid}
                                        type="submit"
                                        onClick={handleSubmit}
                                    >
                                        Calculate
                                    </button>

                                    <button
                                        type="button"
                                        onClick={() => {
                                            onReset()
                                            resetForm()
                                        }}
                                        className="reset-btn"
                                    >
                                        Reset
                                    </button>

                                    <Link
                                        className="btn btn-grey"
                                        data-disabled={!maxSpeedInMph}
                                        to={{
                                            pathname: '/truck-scale/maximum-speed/report',
                                            state: {
                                                maxSpeed: displayMaxSpeed,
                                                minimumTimeOnScaleInSeconds,
                                                scaleLength: values.scaleLength
                                                    ? values.scaleLength
                                                    : 0,
                                                wheelbase: values.wheelbase ? values.wheelbase : 0
                                            }
                                        }}
                                    >
                                        View Report
                                    </Link>
                                </div>
                            </div>
                        </>
                    )
                }}
            </Formik>
        </div>
    )
}

export default MaximumSpeedCalculator
