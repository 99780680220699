import * as Yup from 'yup'

const ClassIIIValidation = Yup.object().shape({
    capacity: Yup.number()
        .required()
        .min(0),
    division: Yup.number()
        .required()
        .min(0),
    units: Yup.string().required()
})

const LivestockValidation = Yup.object().shape({
    dimension: Yup.string().required(),
    headCount: Yup.number()
        .required()
        .min(0),
    weightPerHead: Yup.number()
        .required()
        .min(0),
    pricePerPound: Yup.number()
        .required()
        .min(0),
    shrinkAtPos: Yup.number()
        .required()
        .min(0)
        .max(100),
    shrinkToMove: Yup.number()
        .required()
        .min(0)
        .max(100)
})

const MicrovoltsValidation = Yup.object().shape({
    capacity: Yup.number()
        .required()
        .min(0),
    loadCells: Yup.number()
        .required()
        .min(0),
    excitation: Yup.number()
        .required()
        .min(0),
    mvLoadCells: Yup.number()
        .required()
        .min(0),
    countBy: Yup.number()
        .required()
        .min(0)
})

const TruckScaleMaximumSpeedValidation = Yup.object().shape({
    wheelbase: Yup.number()
        .required('Vehicle Wheelbase is required')
        .positive('Vehicle Wheelbase must be greater than 0'),
    scaleLength: Yup.number()
        .required('Scale Length is required')
        .positive('Scale Length must be greater than 0')
        .when('wheelbase', (wheelbase, schema) =>
            schema.test(
                'scaleLength',
                'Scale Length must be greater than Vehicle Wheelbase',
                value => value > wheelbase
            )
        )
})

const TruckScaleCarbonFootprintValidation = Yup.object().shape({
    averageTimeOnScaleInSeconds: Yup.number()
        .required('Average Time on Scale is required')
        .positive('Average Time on Scale must be greater than 0'),
    costOfDieselFuelPerGallon: Yup.number()
        .required('Cost of Diesel Fuel is required')
        .positive('Cost of Diesel Fuel must be greater than 0'),
    daysOfOperationPerWeek: Yup.number()
        .required('Days of Operation is required')
        .positive('Days of Operation must be greater than 0')
        .max(7, 'Days of Operation cannot be more than 7'),
    numberOfTrucksPerDay: Yup.number()
        .required('Number of Trucks is required')
        .positive('Number of Trucks must be greater than 0')
})

const TruckScaleTransactionCapacityValidation = Yup.object().shape({
    currentNumberOfTrucksPerDay: Yup.number()
        .required('Number of Trucks Per Day is required')
        .positive('Number of Trucks Per Day must be greater than 0'),
    averageTimeOnScaleInSeconds: Yup.number()
        .required('Average Time on Scale is required')
        .positive('Average Time on Scale must be greater than 0'),
    hoursOfOperationEachDay: Yup.number()
        .required('Hours of Operation is required')
        .positive('Hours of Operation must be greater than 0')
        .max(24, 'Hours of Operation cannot be more than 24'),
    lengthOfScaleInFeet: Yup.number()
        .required('Length of Scale is required')
        .positive('Length of Scale must be greater than 0')
        .when('lengthOfVehicleWheelbaseInFeet', (wheelbase, schema) =>
            schema.test(
                'lengthOfScaleInFeet',
                'Length of Scale must be greater than Vehicle Wheelbase',
                value => value > wheelbase
            )
        ),
    lengthOfVehicleWheelbaseInFeet: Yup.number()
        .required('Length of Vehicle Wheelbase is required')
        .positive('Length of Vehicle Wheelbase must be greater than 0')
})

export {
    MicrovoltsValidation,
    ClassIIIValidation,
    LivestockValidation,
    TruckScaleCarbonFootprintValidation,
    TruckScaleMaximumSpeedValidation,
    TruckScaleTransactionCapacityValidation
}
