import React, { useState } from 'react'

import HeaderLogo from '../../assets/images/logo.png'
import Layout from '../../components/Layout'

const calculate = (
    ratePerMinute,
    costPerWeight,
    hoursPerDay,
    accuracyOfCheckweigher,
    daysPerWeek,
    weeksPerSeason,
    overfillPerPackage,
    costOfCheckweigher
) => {
    const ratePerMinuteNumber = Number(ratePerMinute)
    const costPerWeightNumber = Number(costPerWeight)
    const hoursPerDayNumber = Number(hoursPerDay)
    const accuracyOfCheckweigherNumber = Number(accuracyOfCheckweigher)
    const daysPerWeekNumber = Number(daysPerWeek)
    const weeksPerSeasonNumber = Number(weeksPerSeason)
    const overfillPerPackageNumber = Number(overfillPerPackage)
    const costOfCheckweigherNumber = Number(costOfCheckweigher)

    const packagesPerWeek =
        ratePerMinuteNumber * 60 * hoursPerDayNumber * daysPerWeekNumber * weeksPerSeasonNumber
    const overfillCosts = packagesPerWeek * costPerWeightNumber * overfillPerPackageNumber
    const costsWithACheckweigher =
        packagesPerWeek * costPerWeightNumber * accuracyOfCheckweigherNumber
    const savingsPerYear = overfillCosts - costsWithACheckweigher

    const moneyFormatter = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0
    })

    return {
        packagesPerWeek: packagesPerWeek.toLocaleString('en-US'),
        overfillCosts: moneyFormatter.format(overfillCosts),
        costsWithACheckweigher: moneyFormatter.format(costsWithACheckweigher),
        savingsPerYear: moneyFormatter.format(savingsPerYear),
        paybackInDays: Math.ceil(
            costOfCheckweigherNumber /
                ((savingsPerYear - costOfCheckweigherNumber) /
                    (daysPerWeekNumber * weeksPerSeasonNumber))
        ).toLocaleString('en-US')
    }
}

const MotoweighRoi = () => {
    const [page, setPage] = useState('inputs')
    const [showErrorOnEmptyInput, setShowErrorOnEmptyInput] = useState(false)

    const [packageRatePerMinute, setPackageRatePerMinute] = useState('')
    const [productCostPerWeight, setProductCostPerWeight] = useState('')
    const [hoursPerDayOperating, setHoursPerDayOperating] = useState('')
    const [accuracyOfCheckweigher, setAccuracyOfCheckweigher] = useState('')
    const [daysPerWeekOperating, setDaysPerWeekOperating] = useState('')
    const [weeksPerSeasonOperating, setWeeksPerSeasonOperating] = useState('')
    const [averageAmountOverfill, setAverageAmountOverfill] = useState('')
    const [costOfCheckweigher, setCostOfCheckweigher] = useState('')

    // const [packageRatePerMinute, setPackageRatePerMinute] = useState('20')
    // const [productCostPerWeight, setProductCostPerWeight] = useState('2.94')
    // const [hoursPerDayOperating, setHoursPerDayOperating] = useState('14')
    // const [accuracyOfCheckweigher, setAccuracyOfCheckweigher] = useState('.1')
    // const [daysPerWeekOperating, setDaysPerWeekOperating] = useState('6')
    // const [weeksPerSeasonOperating, setWeeksPerSeasonOperating] = useState('3')
    // const [averageAmountOverfill, setAverageAmountOverfill] = useState('.5')
    // const [costOfCheckweigher, setCostOfCheckweigher] = useState('55000')

    const [calculatedPackagesPerWeek, setCalculatedPackagesPerWeek] = useState('00')
    const [calculatedOverfillCosts, setCalculatedOverfillCosts] = useState('00.00')
    const [calculatedCostsWithACheckweigher, setCalculatedCostsWithACheckweigher] = useState(
        '00.00'
    )
    const [calculatedSavingsPerYear, setCalculatedSavingsPerYear] = useState('$00.00')
    const [calculatedPaybackInDays, setCalculatedPaybackInDays] = useState('00')

    // lb or kg
    const [unitSelected, setUnitSelected] = useState('lb')

    const RenderField = (label, fieldValue, onChange, unitsLabel) => {
        const normalizedLabel = label?.toLowerCase().replace(' ', '-')

        return (
            <div className="col-xs-12 col-sm-6 print-xs-12" key={label}>
                <label
                    htmlFor={normalizedLabel}
                    className={showErrorOnEmptyInput && !fieldValue ? 'red' : undefined}
                >
                    {label}
                    <div className="moto-input-wrapper">
                        <input
                            id={normalizedLabel}
                            value={fieldValue}
                            type="number"
                            step="any"
                            onChange={e => {
                                const {
                                    target: { value }
                                } = e
                                if (value !== '' && !Number.isNaN(Number(value))) {
                                    onChange(Number(value))
                                } else {
                                    onChange('')
                                }
                            }}
                        />
                        <div className="moto-input-units">{unitsLabel}</div>
                    </div>
                </label>
            </div>
        )
    }

    const inputs = [
        {
            label: 'What is the line package rate per minute?',
            fieldValue: packageRatePerMinute,
            onChange: setPackageRatePerMinute,
            unitsLabel: 'Per Min'
        },
        {
            label: 'What does the product cost per lb/kg?',
            fieldValue: productCostPerWeight,
            onChange: setProductCostPerWeight,
            unitsLabel: unitSelected === 'lb' ? 'Per Lb' : 'Per Kg'
        },
        {
            label: 'How many hours per day is the line operate?',
            fieldValue: hoursPerDayOperating,
            onChange: setHoursPerDayOperating,
            unitsLabel: 'Per Day'
        },
        {
            label: 'What is the accuracy of the checkweigher?',
            fieldValue: accuracyOfCheckweigher,
            onChange: setAccuracyOfCheckweigher,
            unitsLabel: unitSelected === 'lb' ? 'Lb' : 'Kg'
        },
        {
            label: 'How many days per week is the line operate?',
            fieldValue: daysPerWeekOperating,
            onChange: setDaysPerWeekOperating,
            unitsLabel: 'Per Week'
        },
        {
            label: 'How many weeks per season does the line operate?',
            fieldValue: weeksPerSeasonOperating,
            onChange: setWeeksPerSeasonOperating,
            unitsLabel: 'Weeks'
        },
        {
            label: 'What is the average amount overfill per package?',
            fieldValue: averageAmountOverfill,
            onChange: setAverageAmountOverfill,
            unitsLabel: unitSelected === 'lb' ? 'Lb' : 'Kg'
        },
        {
            label: 'What is the cost of the checkweigher?',
            fieldValue: costOfCheckweigher,
            onChange: setCostOfCheckweigher,
            unitsLabel: '$USD'
        }
    ]

    const ResetFields = () => {
        setShowErrorOnEmptyInput(false)
        inputs.forEach(input => {
            input.onChange('')
        })

        setCalculatedPackagesPerWeek('00')
        setCalculatedOverfillCosts('$00.00')
        setCalculatedCostsWithACheckweigher('$00.00')
        setCalculatedSavingsPerYear('$00.00')
        setCalculatedPaybackInDays('00')
    }

    const CalculateValues = () => {
        setShowErrorOnEmptyInput(true)

        if (inputs.filter(field => !field.fieldValue).length > 0) {
            return
        }

        const {
            packagesPerWeek,
            overfillCosts,
            costsWithACheckweigher,
            savingsPerYear,
            paybackInDays
        } = calculate(
            packageRatePerMinute,
            productCostPerWeight,
            hoursPerDayOperating,
            accuracyOfCheckweigher,
            daysPerWeekOperating,
            weeksPerSeasonOperating,
            averageAmountOverfill,
            costOfCheckweigher
        )

        setCalculatedPackagesPerWeek(packagesPerWeek)
        setCalculatedOverfillCosts(overfillCosts)
        setCalculatedCostsWithACheckweigher(costsWithACheckweigher)
        setCalculatedSavingsPerYear(savingsPerYear)
        setCalculatedPaybackInDays(paybackInDays)
    }

    const ReportUnitLabel = unitSelected === 'lb' ? 'Lb' : 'Kg'

    return (
        <Layout>
            {page === 'inputs' && (
                <div id="page-main" className="section-wrapper page-content">
                    <h1>
                        Motoweigh<sup>&reg;</sup> Return on Investment Calculator
                    </h1>

                    <div className="col-section col-no-margin moto-col hide-print">
                        <div className="col-xs-12 col-sm-3">
                            <label htmlFor="lbs">
                                <input
                                    type="radio"
                                    name="lbs"
                                    id="lbs"
                                    checked={unitSelected === 'lb'}
                                    onChange={() => setUnitSelected('lb')}
                                />
                                &nbsp;Pounds
                            </label>
                        </div>
                        <div className="col-xs-12 col-sm-3">
                            <label htmlFor="kgs">
                                <input
                                    type="radio"
                                    name="kgs"
                                    id="kgs"
                                    checked={unitSelected === 'kg'}
                                    onChange={() => setUnitSelected('kg')}
                                />
                                &nbsp;Kilograms
                            </label>
                        </div>
                    </div>
                    <div className="col-section col-no-margin moto-col">
                        {inputs.map(({ label, fieldValue, onChange, unitsLabel }) =>
                            RenderField(label, fieldValue, onChange, unitsLabel)
                        )}
                    </div>
                    <div className="col-section col-no-margin moto-col hide-print">
                        <div className="col-xs-12 col-sm-3" />
                        <div className="col-xs-12 col-sm-3 ">
                            <button
                                type="button"
                                className="btn-full"
                                onClick={() => CalculateValues()}
                            >
                                Calculate
                            </button>
                        </div>
                        <div className="col-xs-12 col-sm-3">
                            <button
                                className="reset-btn btn-full"
                                type="button"
                                onClick={() => ResetFields()}
                            >
                                Reset
                            </button>
                        </div>
                    </div>

                    <div className="col-section col-no-margin moto-result-container moto-col">
                        <div className="moto-result-row">
                            <div className="moto-block">
                                <p className="moto-header">Packages Per Week</p>
                                <p className="moto-red">{calculatedPackagesPerWeek}</p>
                            </div>
                            <div className="moto-block">
                                <p className="moto-header">Current OverFill Costs</p>
                                <p className="moto-red">{calculatedOverfillCosts}</p>
                            </div>
                            <div className="moto-block">
                                <p className="moto-header">Overfill Costs with a Checkweigher</p>
                                <p className="moto-red">{calculatedCostsWithACheckweigher}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-section col-no-margin moto-result-container">
                        <div className="moto-result-row">
                            <div className="moto-block">
                                <p className="moto-header">Savings Per Year</p>
                                <p className="moto-green">{calculatedSavingsPerYear}</p>
                            </div>
                            <div className="moto-block">
                                <p className="moto-header">Payback in Days</p>
                                <p className="moto-grey">{calculatedPaybackInDays}</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-section col-no-margin moto-col hide-print">
                        <div className="col-xs-12 col-sm-4" />
                        <div className="col-xs-12 col-sm-4 ">
                            <button
                                type="button"
                                className="btn-full"
                                onClick={() => setPage('results')}
                            >
                                View Report
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {page === 'results' && (
                <div id="page-main" className="section-wrapper page-content">
                    <div className="col-section col-no-margin moto-col moto-timestamp-header show-print">
                        <div className="col-xs-12 col-sm-6 print-xs-6">
                            <p>
                                {new Date().toLocaleDateString()} {new Date().toLocaleTimeString()}
                            </p>
                        </div>
                        <div className="col-xs-12 col-sm-6 print-xs-6">
                            <p>From URL: {window.location.href}</p>
                        </div>
                    </div>

                    <div className="col-section col-no-margin moto-col">
                        <div className="col-xs-12 col-sm-8 print-xs-8">
                            <h3>
                                Motoweigh<sup>&reg;</sup> Checkweigher Savings Calculations
                            </h3>
                        </div>
                        <div className="col-xs-12 col-sm-4 print-xs-4 hide-print">
                            <button type="button" className="btn-" onClick={() => window.print()}>
                                Print or Save PDF
                            </button>
                        </div>
                    </div>

                    <div className="col-section col-no-margin moto-col">
                        <table id="report-table">
                            <thead>
                                <tr>
                                    <th>INPUT</th>
                                    <th>RESULTS</th>
                                </tr>
                            </thead>
                            <tr className="result-row">
                                <td>Package Rate Per Minute</td>
                                <td>{packageRatePerMinute} units per min</td>
                            </tr>
                            <tr className="result-row">
                                <td>Line operating hours per day</td>
                                <td>{hoursPerDayOperating} hours</td>
                            </tr>
                            <tr className="result-row">
                                <td>Number of operating days per week</td>
                                <td>{daysPerWeekOperating} days</td>
                            </tr>
                            <tr className="result-row">
                                <td>Average overfill per package</td>
                                <td>
                                    {averageAmountOverfill} {ReportUnitLabel}s
                                </td>
                            </tr>
                            <tr className="result-row">
                                <td>Product cost </td>
                                <td>
                                    ${productCostPerWeight} per {ReportUnitLabel}
                                </td>
                            </tr>
                            <tr className="result-row">
                                <td>Accuracy of the checkweigher </td>
                                <td>
                                    {accuracyOfCheckweigher} {ReportUnitLabel}s
                                </td>
                            </tr>
                            <tr className="result-row">
                                <td>Operating weeks</td>
                                <td>{weeksPerSeasonOperating} weeks</td>
                            </tr>
                            <tr className="result-row">
                                <td>Packages per week</td>
                                <td>{calculatedPackagesPerWeek} per week</td>
                            </tr>
                            <tr className="result-row">
                                <td>Current overfill cost per year</td>
                                <td>{calculatedOverfillCosts}</td>
                            </tr>
                            <tr className="result-row">
                                <td>Overfill cost with a checkweigher</td>
                                <td>{calculatedCostsWithACheckweigher}</td>
                            </tr>
                            <tr className="result-row">
                                <td>Savings per year with a checkweigher</td>
                                <td>{calculatedSavingsPerYear}</td>
                            </tr>
                            <tr className="result-row">
                                <td>Cost of checkweigher</td>
                                <td>${costOfCheckweigher}</td>
                            </tr>
                            <tr className="result-row">
                                <td>Payback in days </td>
                                <td>{calculatedPaybackInDays} days</td>
                            </tr>
                        </table>
                    </div>

                    <div className="result-image">
                        <img
                            className="show-print"
                            src={HeaderLogo}
                            width="240"
                            alt="rice lake logo"
                        />
                        <button
                            type="button"
                            onClick={() => {
                                setPage('inputs')
                            }}
                            className="btn btn-primary hide-print"
                        >
                            View Inputs
                        </button>
                    </div>
                </div>
            )}
        </Layout>
    )
}

export default MotoweighRoi
