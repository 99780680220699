import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import ReportTable from './ReportTable'
import calculators from '../calculators'

const calculator = calculators.find(x => x.slug === 'carbon-footprint')

const CarbonFootprintReport = () => {
    const {
        state = {
            averageTimeOnScaleInSeconds: 0,
            costOfDieselFuelPerGallon: 0,
            daysOfOperationPerWeek: 0,
            gallonsOfFuelBurnedAtIdlePerYear: 0,
            gallonsOfFuelBurnedAtIdlePerYearDisplay: 0,
            numberOfTrucksPerDay: 0,
            potentialCarbonFootprintReductionInKilogramsOfCo2: 0,
            potentialCarbonFootprintReductionInKilogramsOfCo2Display: 0,
            valueOfWastedDieselFuel: 0,
            valueOfWastedDieselFuelDisplay: 0
        }
    } = useLocation()

    const onPrint = () => {
        window.print()
    }

    return (
        <div>
            <h1>{calculator.title}</h1>
            <div className="button-container">
                <button className="no-print" onClick={onPrint} type="button">
                    Print or Save PDF
                </button>
            </div>

            <ReportTable>
                <tr>
                    <td className="input-column">Average Time on Scale (in seconds)</td>
                    <td className="results-column">{state.averageTimeOnScaleInSeconds} seconds</td>
                </tr>
                <tr>
                    <td>Number of Trucks (per day)</td>
                    <td>{state.numberOfTrucksPerDay} per day</td>
                </tr>
                <tr>
                    <td>Days of Operation (per week)</td>
                    <td>{state.daysOfOperationPerWeek} days per week</td>
                </tr>
                <tr>
                    <td>Cost of Diesel Fuel (per gallon)</td>
                    <td>${state.costOfDieselFuelPerGallon} per gallon</td>
                </tr>
                <tr>
                    <td>Gallons of Fuel Burned at Idle (gallons per year)</td>
                    <td>{state.gallonsOfFuelBurnedAtIdlePerYearDisplay} gallons per year</td>
                </tr>
                <tr>
                    <td>Value of Wasted Diesel Fuel (in USD)</td>
                    <td>
                        {state.valueOfWastedDieselFuel !== 0
                            ? state.valueOfWastedDieselFuelDisplay
                            : '$0.00'}
                        (USD)
                    </td>
                </tr>
                <tr>
                    <td>Potential Carbon Footprint Reduction (in kilograms of Co2)</td>
                    <td>{state.potentialCarbonFootprintReductionInKilogramsOfCo2Display} kg</td>
                </tr>
            </ReportTable>

            <div className="button-container">
                <Link
                    className="btn btn-grey no-print"
                    to={{
                        pathname: '/truck-scale/carbon-footprint',
                        state
                    }}
                >
                    Return to Inputs
                </Link>
            </div>
        </div>
    )
}

export default CarbonFootprintReport
