import React from 'react'
import Layout from '../../components/Layout'
import TruckScaleCalculatorList from './TruckScaleCalculatorList'

const TruckScaleLanding = () => {
    return (
        <Layout>
            <div id="page-main" className="section-wrapper page-content">
                <h1>SURVIVOR® OTR-IMS In-motion Truck Scale Calculators</h1>

                <p>
                    These three calculators can be used to determine maximum speed when crossing the
                    scale, estimated fuel savings and estimated increase in transaction capacity
                    when using the SURVIVOR OTR-IMS.
                </p>

                <TruckScaleCalculatorList />

                <p style={{ fontStyle: 'italic' }}>
                    All calculations are theoretical and may differ from actual results based on the
                    vehicles processed, individual site procedures and environmental conditions.
                    Rice Lake Weighing Systems is not responsible for any differences between
                    theoretical and actual results.
                </p>
            </div>
        </Layout>
    )
}

export default TruckScaleLanding
