import React from 'react'
import { PropTypes } from 'prop-types'
import { useField } from 'formik'

const ScaleToolsField = ({ label, showErrorText = false, ...props }) => {
    const [field, meta] = useField(props)
    const { touched, error } = meta

    const { name } = field
    return (
        <>
            <label htmlFor={name} className={error && touched ? 'red' : ''}>
                {label}
            </label>
            <input id={name} {...field} {...props} className={error && 'red'} />
            {touched && error && showErrorText && <div className="red field-error">{error}</div>}
        </>
    )
}

ScaleToolsField.propTypes = {
    label: PropTypes.string.isRequired,
    showErrorText: PropTypes.bool
}

ScaleToolsField.defaultProps = {
    showErrorText: false
}

export default ScaleToolsField
