/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react'
import { Link, NavLink } from 'react-router-dom'

import HeaderLogo from '../assets/images/rice-lake-logo@2x.png'

const Header = () => {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <header>
            <div id="header-skip-container" role="navigation">
                <a id="header-skip" href="#page-main">
                    Skip to content
                </a>
            </div>
            <div className="content-wrapper">
                <Link to="/">
                    <img id="logo" src={HeaderLogo} width="143" alt="rice lake logo" />
                </Link>
            </div>
            <div
                id="mobile-toggle"
                aria-controls="main-nav"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={() => {
                    setIsOpen(!isOpen)
                }}
            >
                <input type="checkbox" aria-label="toggle mobile menu" />
                <span />
            </div>
            <nav id="main-nav" style={{ display: isOpen ? '' : 'none' }}>
                <div className="content-wrapper">
                    <ul>
                        <li>
                            <NavLink to="/weight-conversion" activeClassName="active">
                                Weight Conversion App
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/load-cell-wiring" activeClassName="active">
                                Load Cell Wiring Guide
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/uv-graduation" activeClassName="active">
                                µV Graduation App
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/class-iii-tolerance" activeClassName="active">
                                Class III Tolerance App
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/livestock-shrink" activeClassName="active">
                                Livestock Shrink Calculator
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/motoweigh-roi" activeClassName="active">
                                MotoWeigh ROI Calculator
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/truck-scale" activeClassName="active">
                                SURVIVOR OTR-IMS In-motion Truck Scale Calculators
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    )
}

export default Header
