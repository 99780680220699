import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import calculators from '../calculators'
import ReportTable from './ReportTable'

const calculator = calculators.find(x => x.slug === 'transaction-capacity')

const TransactionCapacityReport = () => {
    const {
        state = {
            averageTimeOnScaleInSeconds: 0,
            hoursOfOperationEachDay: 0,
            lengthOfScale: 0,
            lengthOfVehicleWheelbase: 0,
            maximumSpeedOverScaleInMph: 0,
            maximumSpeedOverScaleInMphDisplay: 0,
            minimumTimeOnScale: 0,
            numberOfTrucksPerDay: 0,
            potentialIncreaseInTrucksPerDay: 0,
            potentialIncreaseInTrucksPerDayDisplay: 0,
            potentialIncreaseInTrucksPerHour: 0,
            potentialIncreaseInTrucksPerHourDisplay: 0,
            reductionOfTimeOnScaleInSeconds: 0
        }
    } = useLocation()

    const onPrint = () => {
        window.print()
    }

    return (
        <div>
            <h1>{calculator.title}</h1>
            <div className="button-container">
                <button className="no-print" onClick={onPrint} type="button">
                    Print or Save PDF
                </button>
            </div>

            <ReportTable>
                <tr>
                    <td className="input-column">Number of Trucks Per Day</td>
                    <td className="results-column">{state.numberOfTrucksPerDay} per day</td>
                </tr>
                <tr>
                    <td>Average Time on Scale in Seconds</td>
                    <td>{state.averageTimeOnScaleInSeconds} seconds</td>
                </tr>
                <tr>
                    <td>Hours of Operation Each Day</td>
                    <td>{state.hoursOfOperationEachDay} hours</td>
                </tr>
                <tr>
                    <td>Length of Scale</td>
                    <td>{state.lengthOfScale} feet</td>
                </tr>
                <tr>
                    <td>Length of Vehicle Wheelbase</td>
                    <td>{state.lengthOfVehicleWheelbase} feet</td>
                </tr>
                <tr>
                    <td>Minimum Time on Scale</td>
                    <td>{state.minimumTimeOnScale} seconds</td>
                </tr>
                <tr>
                    <td>Maximum Speed Over Scale</td>
                    <td>{state.maximumSpeedOverScaleInMphDisplay} mph</td>
                </tr>
                <tr>
                    <td>Potential Increase in Trucks Per Hours</td>
                    <td>{state.potentialIncreaseInTrucksPerHourDisplay} per hour</td>
                </tr>
                <tr>
                    <td>Potential Increase in Trucks Per Day</td>
                    <td>{state.potentialIncreaseInTrucksPerDayDisplay} per day</td>
                </tr>
            </ReportTable>

            <div className="button-container">
                <Link
                    className="btn btn-grey no-print"
                    to={{
                        pathname: '/truck-scale/transaction-capacity',
                        state
                    }}
                >
                    Return to Inputs
                </Link>
            </div>
        </div>
    )
}

export default TransactionCapacityReport
