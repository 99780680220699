import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const TruckScaleCalculatorCard = ({ slug, title, cardDescription, imageAlt, imageSrc }) => {
    return (
        <Link to={`/truck-scale/${slug}`}>
            <img src={imageSrc} alt={imageAlt} />
            <h2 style={{ marginTop: '10px' }}>{title}</h2>
            <p>{cardDescription}</p>
            <button type="button">Start Calculating</button>
        </Link>
    )
}

TruckScaleCalculatorCard.propTypes = {
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    cardDescription: PropTypes.string.isRequired,
    imageAlt: PropTypes.string.isRequired,
    imageSrc: PropTypes.string.isRequired
}

export default TruckScaleCalculatorCard
